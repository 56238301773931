/** @format */

import React, { useEffect } from "react";
// import Link from "next/link";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
// Material Menu Icon
import VibrationOutlinedIcon from "@material-ui/icons/VibrationOutlined";
import AddPhotoAlternateOutlined from "@material-ui/icons/AddPhotoAlternateOutlined";
// import VideoCallOutlined from "@material-ui/icons/VideoCallOutlined";
// import SettingsEthernetOutlinedIcon from "@material-ui/icons/SettingsEthernetOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
// import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
// import ReceiptIcon from "@material-ui/icons/Receipt";

const Sidebar = observer(() => {
  const router = useRouter();
  const settings = useSettings();

  /* 사이드바의 메뉴를 Collapse(접었다 폈다)로 표현하고 싶을 때 사용 */
  useEffect(() => {
    settings.menuState = router.pathname;
  }, [router.pathname]);

  /* 헤더의 grid 아이콘 클릭 시 사이드바에 아이콘만 표시되도록 css 적용. 
    아래 코드는 아이콘만 표시되었을 때 마우스가 아이콘 위로 올라가면 해당하는 메뉴가 보이도록 
    css를 변경하는 코드.*/
  useEffect(() => {
    document.querySelector("#sidebar").classList.remove("active");
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });

      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [settings.sidebarOpen]);

  /* 메뉴 이동 */
  const handleSidebarMenuClick = (path) => {
    settings.sidebarOpen = !settings.sidebarOpen;
    router.push(path);
  };

  /* 개발자 메뉴 */
  return (
    <nav className="sidebar sidebar-offcanvas fixed" id="sidebar">
      <ul className="nav">
        {/* 앱 관리 */}
        <li className={`nav-item ${router.pathname.startsWith("/dev/myApp") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/myApp")}>
            <i className="menu-icon flex justify-center">
              <VibrationOutlinedIcon className="center" />
            </i>
            <span className="menu-title">앱 관리</span>
          </a>
        </li>
        {/* 앱 만들기 */}
        <li className={`nav-item ${router.pathname.startsWith("/dev/createApp") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/createApp")}>
            <i className="menu-icon flex justify-center">
              <AddPhotoAlternateOutlined className="center" />
            </i>
            <span className="menu-title">앱 만들기</span>
          </a>
        </li>
        {/* 데이터 분석 */}
        {/* <li className={`nav-item ${router.pathname.startsWith("/dev/dataAnalysis") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/dataAnalysis")}>
            <i className="menu-icon flex justify-center">
              <SettingsEthernetOutlinedIcon className="center" />
            </i>
            <span className="menu-title">데이터 분석 앱 만들기</span>
          </a>
        </li>
   */}
        {/* 한줄 긋기 */}
        <li>
          <hr className="mx-1" />
        </li>
        {/* <li className={`nav-item ${router.pathname.startsWith("/dev/profit") ? "active" : ""}`}>
            <div
              className={`nav-link ${settings.menuState.startsWith("/dev/profit") === true ? "menu-expanded" : ""}`}
              onClick={() =>
                settings.menuState.startsWith("/dev/profit") === true
                  ? (settings.menuState = "")
                  : (settings.menuState = "/dev/profit")
              }
              data-toggle="collapse"
            >
              <i className="menu-icon fa fa-won flex justify-center"></i>
              <span className="menu-title">수익</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={settings.menuState.startsWith("/dev/profit") === true}>
              <ul className="nav flex-column sub-menu pl-5">
                <li className="nav-item">
                  <Link href="/dev/profit/settlementInfo">
                    <div className={`nav-link ${router.pathname === "/dev/profit/settlementInfo" ? "active" : ""}`}>정산 정보 관리</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/dev/profit/settlementMonth">
                    <div className={`nav-link ${router.pathname === "/dev/profit/settlementMonth" ? "active" : ""}`}>월별 수익 조회</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/dev/profit/settlementProduct">
                    <div className={`nav-link ${router.pathname === "/dev/profit/settlementProduct" ? "active" : ""}`}>
                      상품별 수익 조회
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/dev/profit/settlementList">
                    <div className={`nav-link ${router.pathname.startsWith("/dev/profit/settlementList") ? "active" : ""}`}>
                      입금신청 및 내역조회
                    </div>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
        {/* 댓글 */}
        <li className={`nav-item ${router.pathname.startsWith("/dev/comment") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/comment")}>
            <i className="menu-icon flex justify-center">
              <SmsOutlinedIcon className="center" />
            </i>
            <span className="menu-title">앱 후기</span>
          </a>
        </li>
        {/* 후기 신고 */}
        <li className={`nav-item ${router.pathname.startsWith("/dev/reviewReport") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/reviewReport")}>
            <i className="menu-icon flex justify-center">
              <ReportOutlinedIcon className="center" />
            </i>
            <span className="menu-title">후기 신고</span>
          </a>
        </li>
        {/* 받은 문의 */}
        <li className={`nav-item ${router.pathname.startsWith("/dev/inquireMe") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/inquireMe")}>
            <i className="menu-icon flex justify-center">
              <ChatOutlinedIcon className="center" />
            </i>
            <span className="menu-title">받은 문의</span>
          </a>
        </li>

        {/* 한줄 긋기 */}
        <li>
          <hr className="mx-1" />
        </li>
        <li className={`nav-item ${router.pathname.startsWith("/dev/developerFAQ") ? "active" : ""}`}>
          <a className="nav-link" onClick={() => handleSidebarMenuClick("/dev/developerFAQ")}>
            <i className="menu-icon flex justify-center">
              <HelpOutlineOutlinedIcon className="center" />
            </i>
            <span className="menu-title">개발자 FAQ</span>
          </a>
        </li>
        {/* TODO: 크레딧 기능 추가될 때 같이 주석 제거 */}
        {/* 정산 정보 */}
        {/* <li className={`nav-item ${router.pathname.startsWith("/dev/settlementInfo") ? "active" : ""}`}>
          <button onClick={() => handleSidebarMenuClick("/dev/settlementInfo")}>
            <a className="nav-link">
              <i className="menu-icon flex justify-center">
                <AccountBoxOutlinedIcon className="center" />
              </i>
              <span className="menu-title">정산 정보</span>
            </a>
          </button>
        </li> */}
        {/* 수익 및 정산 */}
        {/* <li className={`nav-item ${router.pathname.startsWith("/dev/profit") ? "active" : ""}`}>
          <button onClick={() => handleSidebarMenuClick("/dev/profit")}>
            <a className="nav-link">
              <i className="menu-icon flex justify-center">
                <ReceiptIcon className="center" />
              </i>
              <span className="menu-title">수익 및 정산</span>
            </a>
          </button>
        </li> */}
        {/* 고객센터 */}
        {/* <li className={`nav-item ${router.pathname.startsWith("/dev/customerService") ? "active" : ""}`}>
          <Link href="/dev/customerService">
            <a className="nav-link">
              <i className="menu-icon ti-help-alt flex justify-center"></i>
              <span className="menu-title">고객센터</span>
            </a>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
});

export default Sidebar;
