/** @format */

import React from "react";
import AdminHeader from "./AdminHeader";
import Sidebar from "./Sidebar";
import AdminFooter from "./AdminFooter";
import ScrollToTop from "./scrollArrow.js";

const AdminLayout = ({ children }) => {
  return (
    <div className="container-scroller">
      <AdminHeader />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          {/* <div className={`${router.pathname.startsWith("/myWork") ? "content-wrapper-mywork" : "content-wrapper"} `}> */}
          <div className="content-wrapper-mywork">
            <main className="h-full">{children}</main>
          </div>
          <ScrollToTop />
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
