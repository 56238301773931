import * as jwt from "jsonwebtoken";
import { projectFirestore } from "components/ProjectFirestore";
import cuid from "cuid";
import { FormatDate } from "components/FormatDate";
import { BasicAlert } from "components/Alert";
import LogOut from "function/LogOut";

/* 회원 비회원 token 값 체크 */
export const JsonWebTokenCheck = async (settings) => {
  // const SECRET_CODE = "sdkjkdlsAESRcdslkch";
  const tokenValue = window.localStorage.getItem("aisoToken");

  /* 회원 토큰 값 체크 */
  if (tokenValue === "" || tokenValue === null) {
    settings.login = false;
    settings.email = "";
    settings.name = "";
    settings.photo = "";
    settings.uid = "";
    // window.localStorage.removeItem("aisoToken");
  } else {
    jwt.verify(tokenValue, process.env.SECRET_CODE, async (error, decoded) => {
      if (error) {
        console.log("로그인 인증 만료");
        // window.localStorage.removeItem("aisoToken");
        LogOut(settings);
      } else {
        const { email, name, uid } = decoded;
        settings.login = true;
        settings.email = email;
        settings.name = name;
        settings.uid = uid;
        const docRef = projectFirestore.collection("users").doc(email);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              settings.photo = doc.data().photo;
            } else {
              BasicAlert("none data");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            BasicAlert(error);
          });

        const setTokenValue = jwt.sign({ email, name, uid }, process.env.SECRET_CODE, {
          expiresIn: "1d",
        });

        window.localStorage.setItem("aisoToken", setTokenValue);
      }
    });
  }

  const guestTokenValue = window.localStorage.getItem("guestToken");

  /* 비회원 토큰 값 체크 => 비회원 토큰값이 없다면 추가해주기 */
  if (guestTokenValue === "" || guestTokenValue === null) {
    // const guestId = `guest_${cuid()}`;
    const guestId = `guest`;
    const checkIn = FormatDate(new Date());
    const appTime = "";
    const tokenValue = jwt.sign({ guestId, checkIn, appTime }, process.env.SECRET_CODE, {
      expiresIn: "1d",
    });

    window.localStorage.setItem("guestToken", tokenValue);
  } else {
    jwt.verify(guestTokenValue, process.env.SECRET_CODE, async (error, decoded) => {
      if (error) {
        console.log("게스트 인증 만료");
        window.localStorage.removeItem("guestToken");

        /* 게스트 토큰 다시 저장 */
        // const guestId = `guest_${cuid()}`;
        const guestId = `guest`;
        const checkIn = FormatDate(new Date());
        const appTime = "";
        const tokenValue = jwt.sign({ guestId, checkIn, appTime }, process.env.SECRET_CODE, {
          expiresIn: "1d",
        });

        window.localStorage.setItem("guestToken", tokenValue);
      }
      // else {
      //   console.log("비회원 토큰 값 ::", decoded);
      // }
    });
  }
};
