// import '@babel/polyfill';
import React, { useEffect } from "react";
import useSettings from "stores/settings";
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import { useRouter } from "next/router";
import "styles/globals.css";
import "styles/app.scss";
import BasicLayout from "components/BasicLayout";
// import VisitorLayout from "pages/visitor/common/VisitorLayout";
import UserLayout from "pages/user/common/UserLayout";
import DevLayout from "pages/dev/common/DevLayout";
import AdminLayout from "pages/admin/common/AdminLayout";
import { JsonWebTokenCheck } from "function/JsonWebTokenCheck";

/* 아임포트 초기 설정 */
// var IMP = window.IMP; // 생략가능
// IMP.init("iamport"); // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용

// let IMP = "";
// if (typeof window !== "undefined") {
//   // 코드 작성
//   IMP = window.IMP; // 생략가능
//   IMP.init("iamport"); // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
// }

const App = ({ Component, pageProps }) => {
  React.useLayoutEffect = React.useEffect;

  // build 파일 실행시 TypeError 해결을 위해 아래 코드 추가
  if (typeof process.versions === "undefined") process.versions = {};

  const settings = useSettings();
  const router = useRouter();

  useEffect(() => {
    /* 비회원 or 회원 나눠서 토큰 값 저장하기 */
    /* 비회원 : guestToken => guestId, checkIn, app */
    /* 회원 : token => login, email, name, photo */
    JsonWebTokenCheck(settings);
  }, []);

  /* 로그인 화면 Layout */
  if (router.pathname.startsWith("/signin") || router.pathname.startsWith("/signup") || router.pathname.startsWith("/password")) {
    return (
      <UserLayout>
        <Component {...pageProps} />
      </UserLayout>
    );
  }

  /* 개발자 페이지 Layout */
  if (router.pathname.startsWith("/dev")) {
    return (
      <DevLayout>
        <Component {...pageProps} />
      </DevLayout>
    );
  }

  /* 개발자 페이지 Layout */
  if (router.pathname.startsWith("/admin")) {
    return (
      <AdminLayout>
        <Component {...pageProps} />
      </AdminLayout>
    );
  }

  /* 기존 페이지 & 사용자 페이지 분리 */
  if (router.pathname.startsWith("/user")) {
    return (
      <UserLayout>
        <Component {...pageProps} />
      </UserLayout>
    );
  }

  /* 404 page */
  return (
    <BasicLayout>
      <Component {...pageProps} />
    </BasicLayout>
  );
};

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps };
};

export default App;
