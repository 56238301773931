/** @format */

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Link from "next/link";
import useSettings from "stores/settings";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
// import { BasicAlert } from "components/Alert";

import { projectFirestore } from "components/ProjectFirestore";
// Material Icon
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
// import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import StorefrontRoundedIcon from "@material-ui/icons/StorefrontRounded";
import LogOut from "function/LogOut";
import HistoryIcon from "@material-ui/icons/History";
import { FormatDate } from "components/FormatDate";

const DevHeader = observer(() => {
  const settings = useSettings();
  const router = useRouter();

  const [myPhoto, setMyPhoto] = useState("");
  const [credit, setCredit] = useState(0);
  const [todayMessageCount, setTodayMessageCount] = useState(0);
  const [message, setMessage] = useState([]);

  const { email, name, photo } = settings;
  useEffect(async () => {
    if (email) {
      const todayDate = FormatDate(new Date());

      const docRef = projectFirestore.collection("users").doc(email);

      const docValue = await docRef.get();
      if (docValue.exists) {
        setMyPhoto(docValue.data().photo);
        setCredit(docValue.data().credit);
      }

      /* 메세지 가져오기 */
      const collectionRef = projectFirestore.collection("users").doc(email).collection("message");

      const tempData = [];
      let todayMessageCount = 0;
      const collectionValue = await collectionRef.orderBy("createdAt", "desc").get();
      collectionValue.forEach((doc) => {
        if (doc.data().receiveEmail === email) {
          tempData.push({ ...doc.data(), id: doc.id });
          const messageDate = doc.data().createdAt.substring(0, 10);
          const today = todayDate.substring(0, 10);
          if (messageDate === today) {
            todayMessageCount += 1;
          }
        }
      });

      setMessage(tempData);
      setTodayMessageCount(todayMessageCount);
    }
  }, [email, photo]);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const sidebarOpenToggle = () => {
    document.body.classList.toggle("sidebar-icon-only");
    settings.sidebarOpen = !settings.sidebarOpen;
  };

  const logoutButtonClick = () => {
    // settings.login = false;
    // settings.email = "";
    // settings.name = "";
    // window.localStorage.removeItem("aisoToken");
    LogOut(settings);
    // router.push("/"); // 로그아웃 하면 첫 화면으로 이동
    router.push("/user/market"); // 로그아웃 하면 첫 화면으로 이동
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      {/* AISO 아이콘 표시, 화면 크기에 따라 아이콘 변경 */}
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link href="/">
          <a className="navbar-brand brand-logo mr-5">
            <img src="/images/header/aiso_logo.png" alt="logo" />
          </a>
        </Link>
        <Link href="/">
          <a className="navbar-brand brand-logo-mini">
            <img src="/images/header/aiso_logo_icon.png" alt="logo" />
          </a>
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        {/* 일정 크기보다 화면이 클 때 표시되는 메뉴 아이콘 */}
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => sidebarOpenToggle()}>
          <span className="ti-layout-grid2"></span>
        </button>
        {/* 오른쪽 아이콘들 */}
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item mr-1"> */}
          <li className="nav-item mr-4">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="ti-email"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <p className="p-3 mb-0 text-black">메시지</p>
                <div className="flex justify-around w-full p-3 border-b-2 border-gray-100">
                  <div>
                    <h3 className="text-primary">{todayMessageCount}</h3>
                  </div>
                  <div>
                    <p className="text-xs mb-0">Today</p>
                    <p>받은 메시지</p>
                  </div>
                </div>
                {message.length > 0 &&
                  message.map((m) => (
                    <Dropdown.Item className="dropdown-item preview-item" key={m.id} onClick={(evt) => evt.preventDefault()}>
                      <Link href="/dev/message">
                        <div className="block">
                          <div className="flex items-center justify-start mt-1">
                            <div className="badge badge-primary badge-pill">받은 메시지</div>
                            <p className="mb-0 font-semibold ml-2 text-xs text-black">{m.sendUserName}</p>
                          </div>
                          <div className="flex items-end justify-start mt-2 w-72">
                            <p className="ml-2 mb-0 w-full truncate-1-lines">{m.message}</p>
                            <small className="text-gray-400 ml-2">{m.createdAt ? m.createdAt.substring(0, 16) : ""}</small>
                          </div>
                        </div>
                      </Link>
                    </Dropdown.Item>
                  ))}

                <Dropdown.Item className="flex justify-end p-3">
                  <Link href="/dev/message">
                    <a className="btn btn-lightGray btn-icon-text btn-sm text-xs">
                      전체 메시지 보기 <i className="ti-arrow-circle-right text-xs"></i>
                    </a>
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/* <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="ti-bell"></i>
                <span className="count"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list w-96">
                <p className="p-3 mb-0 text-black">알림</p>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-warning"></div>
                      <p className="mb-0 ml-2 text-gray-700">앱명</p>
                    </div>
                    <div className="flex items-end justify-start mt-1">
                      <p className="ml-3 mb-0 w-full truncate">10,000 원이 청구되었습니다.</p>
                      <small className="text-gray-400 ml-2">10:00 pm</small>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-lightBlue"></div>
                      <p className="mb-0 ml-2 text-gray-700">앱명</p>
                    </div>
                    <div className="flex items-end justify-start mt-1">
                      <p className="ml-3 mb-0 w-full truncate">
                        Qui mollit occaecat aliqua aliquip cupidatat veniam deserunt proident Lorem fugiat est ad aute pariatur.
                      </p>
                      <small className="text-gray-400 ml-2">10:00 pm</small>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-primary"></div>
                      <p className="mb-0 ml-2 text-gray-700">앱명</p>
                    </div>
                    <div className="flex items-end justify-start mt-1">
                      <p className="ml-3 mb-0 w-full truncate">
                        Qui mollit occaecat aliqua aliquip cupidatat veniam deserunt proident Lorem fugiat est ad aute pariatur.
                      </p>
                      <small className="text-gray-400 ml-2">10:00 pm</small>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-gray"></div>
                      <p className="mb-0 ml-2 text-gray-700">앱명</p>
                    </div>
                    <div className="flex items-end justify-start mt-1">
                      <p className="ml-3 mb-0 w-full truncate">
                        Qui mollit occaecat aliqua aliquip cupidatat veniam deserunt proident Lorem fugiat est ad aute pariatur.
                      </p>
                      <small className="text-gray-400 ml-2">10:00 pm</small>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-lightGray"></div>
                      <p className="mb-0 ml-2 text-gray-700">앱명</p>
                    </div>
                    <div className="flex items-end justify-start mt-1">
                      <p className="ml-3 mb-0 w-full truncate">
                        Qui mollit occaecat aliqua aliquip cupidatat veniam deserunt proident Lorem fugiat est ad aute pariatur.
                      </p>
                      <small className="text-gray-400 ml-2">10:00 pm</small>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="flex justify-end p-3">
                  <Link href="/dev/notice">
                    <a className="btn btn-lightGray btn-icon-text btn-sm text-xs">
                      전체 알림 보기 <i className="ti-arrow-circle-right text-xs"></i>
                    </a>
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}

          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="img-cover-box rounded-full border min-w-min">
                  <img
                    src={myPhoto === "" ? "/images/market/no-img.png" : myPhoto}
                    alt="profile"
                    style={{ width: "38px", height: "38px" }}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown w-96">
                <div className="w-full flex justify-between items-center mt-2 px-4 py-2">
                  {/* <Link href="/user/profile"> */}
                  <div className="flex items-center">
                    <div>
                      <div className="img-cover-box rounded-full border w-10 h-10">
                        <img
                          src={myPhoto === "" ? "/images/market/no-img.png" : myPhoto}
                          alt="profile"
                          style={{ width: "38px", height: "38px" }}
                        />
                      </div>
                    </div>
                    <div className="whitespace-pre-line break-all pl-3 mt-1">
                      <p className="truncate-1-lines mb-0 text-555 font-gmarket-medium text-sm font-bold">{name}</p>
                      <p className="mb-0 text-aaa font-gmarket-medium text-xs">{email}</p>
                    </div>
                  </div>
                  {/* </Link> */}
                  <div className="flex flex-col justify-end items-end">
                    <p className="mb-0 text-ss font-gmarket-medium">Credit</p>
                    <p className="mt-1 mb-0 text-base font-bold font-gmarket-medium text-primary">{credit}</p>
                  </div>
                </div>
                <div className="flex flex-wrap mt-4">
                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/market")}>
                      <div className="flex justify-between items-center">
                        <StorefrontRoundedIcon className="text-primary mr-3"></StorefrontRoundedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2 text-primary">마켓</p>
                      </div>
                    </Dropdown.Item>
                  </div>
                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/aisoSettings")}>
                      <div className="flex justify-between items-center">
                        <PermIdentityOutlinedIcon className="text-333 mr-3"></PermIdentityOutlinedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">프로필</p>
                      </div>
                    </Dropdown.Item>
                  </div>
                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/history")}>
                      <div className="flex justify-between items-center">
                        <HistoryIcon className="text-333 mr-3"></HistoryIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">히스토리</p>
                      </div>
                    </Dropdown.Item>
                  </div>

                  {/* <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/payment")}>
                      <div className="flex justify-between items-center">
                        <CreditCardOutlinedIcon className="text-333 mr-3"></CreditCardOutlinedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">크레딧 충전</p>
                      </div>
                    </Dropdown.Item>
                  </div> */}
                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/inquire")}>
                      <div className="flex justify-between items-center">
                        <ContactSupportOutlinedIcon className="text-333 mr-3"></ContactSupportOutlinedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">내 문의</p>
                      </div>
                    </Dropdown.Item>
                  </div>
                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => router.push("/user/customerService")}>
                      <div className="flex justify-between items-center">
                        <HelpOutlineOutlinedIcon className="text-333 mr-3"></HelpOutlineOutlinedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">고객센터</p>
                      </div>
                    </Dropdown.Item>
                  </div>

                  <div className="w-full">
                    <Dropdown.Item className="w-full" onClick={() => logoutButtonClick()}>
                      <div className="flex justify-between items-center">
                        <PowerSettingsNewOutlinedIcon className="text-333 mr-3"></PowerSettingsNewOutlinedIcon>
                        <p className="font-bold font-gmarket-medium text-xs mt-2">로그아웃</p>
                      </div>
                    </Dropdown.Item>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        {/* 일정 크기보다 화면이 작을 때 표시되는 메뉴 아이콘 */}
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center ml-2 mt-1"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="ti-layout-grid2"></span>
        </button>
      </div>
    </nav>
  );
});

export default DevHeader;
