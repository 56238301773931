export const FormatDate_Short = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  return `${year}-${month.substr(-2)}-${day.substr(-2)}`;
};

export const FormatDate = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  const hour = `0${date.getHours()}`;
  const minute = `0${date.getMinutes()}`;
  const second = `0${date.getSeconds()}`;
  return `${year}-${month.substr(-2)}-${day.substr(-2)} ${hour.substr(-2)}:${minute.substr(-2)}:${second.substr(-2)}`;
};

export const FormatOnlyDate = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  const hour = `0${date.getHours()}`;
  const minute = `0${date.getMinutes()}`;
  const second = `0${date.getSeconds()}`;
  return `${year}${month.substr(-2)}${day.substr(-2)}${hour.substr(-2)}${minute.substr(-2)}${second.substr(-2)}`;
};

/* 어제날짜 구하기 */
export const YesterDayDate = (date) => {
  const yesterDate = date.getTime() - 1 * 24 * 60 * 60 * 1000;
  date.setTime(yesterDate);
  const yesterYear = date.getFullYear();
  let yesterMonth = date.getMonth() + 1;
  let yesterDay = date.getDate();

  if (yesterMonth < 10) {
    yesterMonth = "0" + yesterMonth;
  }
  if (yesterDay < 10) {
    yesterDay = "0" + yesterDay;
  }

  const resultDate = yesterYear + "." + yesterMonth + "." + yesterDay;
  return `${resultDate}`;
};

/* 지난주 날짜 구하기 */
export const LastWeekDate = (date) => {
  const yesterDate = date.getTime() - 7 * 24 * 60 * 60 * 1000;
  date.setTime(yesterDate);
  const yesterYear = date.getFullYear();
  let yesterMonth = date.getMonth() + 1;
  let yesterDay = date.getDate();

  if (yesterMonth < 10) {
    yesterMonth = "0" + yesterMonth;
  }
  if (yesterDay < 10) {
    yesterDay = "0" + yesterDay;
  }

  const resultDate = yesterYear + "." + yesterMonth + "." + yesterDay;
  return `${resultDate}`;
};

/* 지난 년도 지금 날짜 */
export const LastYear = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;

  if (month < 10) {
    month = "0" + month;
  }
  const day = date.getDate();

  return `${year - 1}.${month}.${day}`;
};

/* 지난 달 */
export const LastMonth = (date) => {
  const monthDate = date.getTime() - 30 * 24 * 60 * 60 * 1000;
  date.setTime(monthDate);
  const monthYear = date.getFullYear();
  let monthMonth = date.getMonth() + 1;

  if (monthMonth < 10) {
    monthMonth = "0" + monthMonth;
  }

  const resultDate = monthYear + "." + monthMonth;
  return `${resultDate}`;
};

/* 지난 달 */
export const LastMonth2 = (date) => {
  const monthDate = date.getTime() - 30 * 24 * 60 * 60 * 1000;
  date.setTime(monthDate);
  const monthYear = date.getFullYear();
  let monthMonth = date.getMonth() + 1;

  if (monthMonth < 10) {
    monthMonth = "0" + monthMonth;
  }

  const resultDate = monthYear + "-" + monthMonth;
  return `${resultDate}`;
};
/* 이번 달 */
export const ThisMonth = (date) => {
  const thisYear = date.getFullYear();
  let thisMonth = date.getMonth() + 1;

  if (thisMonth < 10) {
    thisMonth = "0" + thisMonth;
  }

  const resultDate = thisYear + "-" + thisMonth;
  return `${resultDate}`;
};

/* 년, 월, 일 전달 */
export const TheDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return { year, month, day };
};

/* 지난 년, 지난 달, 지난 날 전달 */
export const TheLastDate = (date) => {
  const tempDate1 = new Date(date);
  const tempDate2 = new Date(date);

  const monthDate = tempDate1.getTime() - 30 * 24 * 60 * 60 * 1000;
  tempDate1.setTime(monthDate);
  const yesterMonthYear = tempDate1.getFullYear();
  let yesterMonth = tempDate1.getMonth() + 1;

  if (yesterMonth < 10) {
    yesterMonth = "0" + yesterMonth;
  }

  const yesterDate = tempDate2.getTime() - 1 * 24 * 60 * 60 * 1000;
  tempDate2.setTime(yesterDate);
  const yesterDayYear = tempDate2.getFullYear();
  let yesterDayMonth = tempDate2.getMonth() + 1;
  let yesterDay = tempDate2.getDate();

  if (yesterDayMonth < 10) {
    yesterDayMonth = "0" + yesterDayMonth;
  }

  if (yesterDay < 10) {
    yesterDay = "0" + yesterDay;
  }

  return { yesterMonthYear, yesterDayYear, yesterMonth, yesterDayMonth, yesterDay };
};
