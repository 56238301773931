/** @format */

import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useSettings from "stores/settings";
import { Dropdown } from "react-bootstrap";
import { projectFirestore } from "components/ProjectFirestore";
import { observer } from "mobx-react-lite";
import { JsonWebTokenCheck } from "function/JsonWebTokenCheck";
import LogOut from "function/LogOut";
// Material Icone
import DeveloperModeOutlinedIcon from "@material-ui/icons/DeveloperModeOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import HistoryIcon from "@material-ui/icons/History";

const useStyles = makeStyles({
  list: {
    width: 260,
  },
  fullList: {
    width: "auto",
  },
});

const UserHeader = observer(() => {
  const settings = useSettings();
  const router = useRouter();

  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    JsonWebTokenCheck(settings);
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/market">
                <div className="cursor-pointer">마켓</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem>
        {/* <Divider /> */}
        {/* <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/dataAnalysis">
                <div>데이터 분석</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem> */}
        <Divider />
        <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/home">
                <div>아이소 소개</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/contactus">
                <div>문의하기</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/notice">
                <div>공지사항</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText>
            <li className="nav-item header-menu visitor-header-menu">
              <Link href="/user/beta">
                <div>베타</div>
              </Link>
            </li>
          </ListItemText>
        </ListItem>
      </List>

      {!settings.login ? (
        <ul className="w-full px-2">
          {/* <ul className="absolute bottom-0 right-0 w-full px-2"> */}
          <li className="nav-item px-3 py-2">
            <button className="header-sign-up" onClick={() => signUpButtonClick()}>
              <i className="icon-user-follow mr-3"></i>회원가입
            </button>
          </li>
          <li className="nav-item px-3 py-2">
            <button className="header-sign-in" onClick={() => signInButtonClick()}>
              <i className="icon-login mr-3"></i> 로그인
            </button>
          </li>
        </ul>
      ) : (
        ""
      )}
    </div>
  );

  const signUpButtonClick = () => {
    router.push("/signup");
  };

  const signInButtonClick = () => {
    router.push("/signin");
  };

  return (
    <nav className="navbar col-12 p-0 fixed-top flex visitor-navbar">
      <div className="visitor-navbar-brand-wrapper">
        <div className="nav-pc-hidden">
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>
                <i className="mdi mdi-menu text-3xl text-primary pt-2"></i>
              </Button>
              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
        <div className={`mo-brand-logo ${settings.login ? "flex justify-center" : "mt-2 -ml-4"}`}>
          <Link href="/user/market">
            <div className="navbar-brand brand-logo mo-brand-logo">
              <img src="/images/header/aiso_logo.png" alt="logo" />
            </div>
          </Link>
        </div>
        {/* 모바일 일때  */}
        <div className="nav-pc-hidden">
          {settings.login ? <MobileUserIcon email={settings.email} name={settings.name} photo={settings.photo} /> : ""}
        </div>
      </div>

      <div className="visitor-navbar-menu-wrapper nav-mo-hidden">
        <ul className="navbar-nav navbar-nav-left visitor-nabar-nav">
          <Link href="/user/market">
            <li
              className={`nav-item visitor-header-menu mx-3 ${
                router.pathname.startsWith("/user/market") ? "header-menu-active" : "header-menu"
              }`}
            >
              <div>마켓</div>
            </li>
          </Link>
          {/* <Link href="/user/dataAnalysis">
            <li
              className={`nav-item visitor-header-menu mx-3 ${
                router.pathname.startsWith("/user/dataAnalysis") ? "header-menu-active" : "header-menu"
              }`}
            >
              <div>데이터 분석</div>
            </li>
          </Link> */}
          <Link href="/user/about">
            <li
              className={`nav-item visitor-header-menu mx-3 ${
                router.pathname.startsWith("/user/about") ? "header-menu-active" : "header-menu"
              }`}
            >
              <div>아이소 소개</div>
            </li>
          </Link>
          <Link href="/user/contactus">
            <li
              className={`nav-item visitor-header-menu mx-3 ${
                router.pathname.startsWith("/user/contactus") ? "header-menu-active" : "header-menu"
              }`}
            >
              <div>문의하기</div>
            </li>
          </Link>
          {settings.login ? (
            ""
          ) : (
            <Link href="/user/notice">
              <li
                className={`nav-item visitor-header-menu mx-3 ${
                  router.pathname.startsWith("/user/notice") ? "header-menu-active" : "header-menu"
                }`}
              >
                <div>공지사항</div>
              </li>
            </Link>
          )}
          <Link href="/user/beta">
            <li
              className={`nav-item visitor-header-menu mx-3 ${
                router.pathname.startsWith("/user/beta") ? "header-menu-active" : "header-menu"
              }`}
            >
              <div>베타</div>
            </li>
          </Link>
        </ul>

        {/* 로그인 & 로그아웃 상태 토글 */}
        {settings.email ? (
          <UserIcon email={settings.email} name={settings.name} photo={settings.photo} />
        ) : (
          <ul className="navbar-nav navbar-nav-right visitor-right">
            <li className="nav-item">
              <button
                className={`nav-item visitor-header-menu ${router.pathname.endsWith("signUp") ? "header-menu-active" : "header-menu"}`}
                onClick={() => signUpButtonClick()}
              >
                회원가입
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-item visitor-header-menu ${router.pathname.endsWith("signIn") ? "header-menu-active" : "header-menu"}`}
                onClick={() => signInButtonClick()}
              >
                로그인
              </button>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
});

const UserIcon = ({ email, name, photo }) => {
  const settings = useSettings();
  const router = useRouter();

  // const [myPhoto, setMyPhoto] = useState("");
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    if (email) {
      const docRef = projectFirestore.collection("users").doc(`${email}`);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // setMyPhoto(doc.data().photo);
            setCredit(doc.data().credit);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          BasicAlert(error);
        });
    }
  }, [email, name, photo]);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const logoutButtonClick = () => {
    LogOut(settings);
    router.push("/user/market"); // 로그아웃 하면 첫 화면으로 이동
  };
  return (
    <div className="navbar-menu-wrapper-right-icon d-flex align-items-stretch justify-content-end">
      {/* 오른쪽 아이콘들 */}
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item nav-profile">
          <Dropdown alignRight>
            <Dropdown.Toggle className="nav-link count-indicator">
              <div className="img-cover-box rounded-full border min-w-min">
                <img src={photo === "" ? "/images/market/no-img.png" : photo} alt="profile" style={{ width: "38px", height: "38px" }} />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="preview-list navbar-dropdown w-96">
              <div className="w-full flex justify-between items-center mt-2 px-4 py-2">
                {/* <Link href="/user/profile"> */}
                <div className="flex items-center">
                  <div>
                    <div className="img-cover-box rounded-full border w-10 h-10">
                      <img
                        src={photo === "" ? "/images/market/no-img.png" : photo}
                        alt="profile"
                        style={{ width: "38px", height: "38px" }}
                      />
                    </div>
                  </div>
                  <div className="whitespace-pre-line break-all pl-3 mt-1">
                    <p className="truncate-1-lines mb-0 text-555 font-gmarket-medium text-sm font-bold">{name}</p>
                    <p className="mb-0 text-aaa font-gmarket-medium text-xs">{email}</p>
                  </div>
                </div>
                {/* </Link> */}
                <div className="flex flex-col justify-end items-end">
                  <p className="mb-0 text-ss font-gmarket-medium">Credit</p>
                  <p className="mt-1 mb-0 text-base font-bold font-gmarket-medium text-primary">{credit}</p>
                </div>
              </div>
              <div className="flex flex-wrap mt-4">
                {/* <Dropdown.Item className="dropdown-item preview-it bem" onClick={() => router.push("/profile")}>
                <div className="d-flex align-items-center">
                  <i className="icon-user text-primary"></i>
                  <span className="pl-3">프로필</span>
                </div>
              </Dropdown.Item> */}
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/aisoSettings")}>
                    <div className="flex justify-between items-center">
                      <PermIdentityOutlinedIcon className="text-333 mr-3"></PermIdentityOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">프로필</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/history")}>
                    <div className="flex justify-between items-center">
                      <HistoryIcon className="text-333 mr-3"></HistoryIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">히스토리</p>
                    </div>
                  </Dropdown.Item>
                </div>

                {/* <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/payment")}>
                    <div className="flex justify-between items-center">
                      <CreditCardOutlinedIcon className="text-333 mr-3"></CreditCardOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">크레딧 충전</p>
                    </div>
                  </Dropdown.Item>
                </div> */}
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/inquire")}>
                    <div className="flex justify-between items-center">
                      <ContactSupportOutlinedIcon className="text-333 mr-3"></ContactSupportOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">내 문의</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/customerService")}>
                    <div className="flex justify-between items-center">
                      <HelpOutlineOutlinedIcon className="text-333 mr-3"></HelpOutlineOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">고객센터</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/dev/myApp")}>
                    <div className="flex justify-between items-start">
                      <DeveloperModeOutlinedIcon className="text-333 mr-3"></DeveloperModeOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">앱 개발 스튜디오</p>
                    </div>
                  </Dropdown.Item>
                </div>

                {/* <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="center">
                    <i className="icon-settings text-primary"></i>
                    <p className="font-bold font-gmarket-medium text-xs mt-2">설정</p>
                  </div>
                </Dropdown.Item> */}
                {/* <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}> */}
                {/* <div className="w-1/3">
                  <Dropdown.Item className="w-full center" onClick={() => logoutButtonClick()}>
                    <div className="center">
                      <PowerSettingsNewOutlinedIcon className="text-333"></PowerSettingsNewOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">로그아웃</p>
                    </div>
                  </Dropdown.Item>
                </div> */}
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => logoutButtonClick()}>
                    <div className="flex justify-between items-center">
                      <PowerSettingsNewOutlinedIcon className="text-333 mr-3"></PowerSettingsNewOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">로그아웃</p>
                    </div>
                  </Dropdown.Item>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        {/* <li className="nav-item nav-settings d-none d-lg-block">
            <button type="button" className="nav-link border-0" onClick={toggleRightSidebar}>
              <i className="ti-more"></i>
            </button>
          </li> */}
      </ul>
      {/* 일정 크기보다 화면이 작을 때 표시되는 메뉴 아이콘 */}
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
        <span className="ti-layout-grid2"></span>
      </button>
    </div>
  );
};

/* 모바일 버전 user icon */
const MobileUserIcon = ({ email, name, photo }) => {
  const settings = useSettings();
  const router = useRouter();

  const [myPhoto, setMyPhoto] = useState("");
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    if (email) {
      const docRef = projectFirestore.collection("users").doc(`${email}`);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setMyPhoto(doc.data().photo);
            setCredit(doc.data().credit);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          BasicAlert(error);
        });
    }
  }, [email, name, photo]);

  const logoutButtonClick = () => {
    LogOut(settings);
    router.push("/user/market"); // 로그아웃 하면 첫 화면으로 이동
  };
  return (
    <div className="navbar-menu-wrapper-right-icon-mobile mt-2 d-flex align-items-stretch justify-content-end items-center">
      {/* 오른쪽 아이콘들 */}
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item nav-profile">
          <Dropdown alignRight>
            <Dropdown.Toggle className="nav-link count-indicator">
              <div className="img-cover-box rounded-full border min-w-min" style={{ width: "40px", height: "40px" }}>
                <img src={myPhoto === "" ? "/images/market/no-img.png" : myPhoto} alt="profile" style={{ width: "38px", height: "38px" }} />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="preview-list navbar-dropdown w-96">
              <div className="w-full flex justify-between items-center mt-2 px-4 py-2">
                {/* <Link href="/user/profile"> */}
                <div className="flex items-center">
                  <div>
                    <div className="img-cover-box rounded-full border min-w-min " style={{ width: "40px", height: "40px" }}>
                      <img
                        src={myPhoto === "" ? "/images/market/no-img.png" : myPhoto}
                        alt="profile"
                        style={{ width: "38px", height: "38px" }}
                      />
                    </div>
                  </div>
                  <div className="pl-3 mt-1">
                    <p className="mb-0 text-555 font-gmarket-medium text-sm font-bold">{name}</p>
                    <p className="mb-0 text-aaa font-gmarket-medium text-xs">{email}</p>
                  </div>
                </div>
                {/* </Link> */}
                <div className="flex flex-col justify-end items-end">
                  <p className="mb-0 text-ss font-gmarket-medium">Credit</p>
                  <p className="mt-1 mb-0 text-base font-bold font-gmarket-medium text-primary">{credit}</p>
                </div>
              </div>
              <div className="flex flex-wrap mt-4">
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/aisoSettings")}>
                    <div className="flex justify-between items-center">
                      <PermIdentityOutlinedIcon className="text-333 mr-3"></PermIdentityOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">프로필</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/history")}>
                    <div className="flex justify-between items-center">
                      <HistoryIcon className="text-333 mr-3"></HistoryIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">히스토리</p>
                    </div>
                  </Dropdown.Item>
                </div>

                {/* <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/payment")}>
                    <div className="flex justify-between items-center">
                      <CreditCardOutlinedIcon className="text-333 mr-3"></CreditCardOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">크레딧 충전</p>
                    </div>
                  </Dropdown.Item>
                </div> */}
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/inquire")}>
                    <div className="flex justify-between items-center">
                      <ContactSupportOutlinedIcon className="text-333 mr-3"></ContactSupportOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">내 문의</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/user/customerService")}>
                    <div className="flex justify-between items-center">
                      <HelpOutlineOutlinedIcon className="text-333 mr-3"></HelpOutlineOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">문의하기</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => router.push("/dev/myApp")}>
                    <div className="flex justify-between items-start">
                      <DeveloperModeOutlinedIcon className="text-333 mr-3"></DeveloperModeOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">앱 개발 스튜디오</p>
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="w-full">
                  <Dropdown.Item className="w-full" onClick={() => logoutButtonClick()}>
                    <div className="flex justify-between items-center">
                      <PowerSettingsNewOutlinedIcon className="text-333 mr-3"></PowerSettingsNewOutlinedIcon>
                      <p className="font-bold font-gmarket-medium text-xs mt-2">로그아웃</p>
                    </div>
                  </Dropdown.Item>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
};
export default UserHeader;
